import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchShifts } from "../../../AppDomain/UseCase/Timesheet.fs.js";
import { map as map_1, collect, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { iconButton, showModalDialog } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, alignCellTextTop, Daisy_h5, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_1 } from "./ShiftEdit.fs.js";
import { IndexView as IndexView_2, Msg$2 } from "../BaseSearchAdvanced.fs.js";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { singleton as singleton_1, ofArray } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { DateTime_dateTimeToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { map, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { StatusToSearch as StatusToSearch_1, statusesToSearch, StatusToSearchModule_ofString, StatusToSearchModule_toString, ShiftSearchDetails } from "../../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { PropHelpers_createOnKey } from "../../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../../fable_modules/Feliz.2.8.0/Key.fs.js";
import { name as name_48 } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { String_splitWord } from "../../../../Logos.Shared/Util.fs.js";

export function searchFunc(shiftSearchDetails) {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (pagination) => searchShifts(appRoot.ShiftService, selectedOrgId, shiftSearchDetails, pagination);
}

export function shiftTable(props) {
    const children_7 = toList(delay(() => {
        let matchValue_1, x_1;
        return append((matchValue_1 = props.state.EntityToEdit, (matchValue_1 == null) ? singleton(react.createElement(react.Fragment, {})) : ((x_1 = matchValue_1, singleton(createElement(showModalDialog, {
            dialogId: x_1,
            header: "",
            width: new Daisy_DialogWidth(4, []),
            heightPercent: "90",
            body: () => {
                const matchValue = props.state.EntityToEdit;
                if (matchValue == null) {
                    const xs = [Daisy_h5("No Shift selected.")];
                    return react.createElement(react.Fragment, {}, ...xs);
                }
                else {
                    return createElement(IndexView_1, {
                        shiftId: matchValue,
                    });
                }
            },
            onShow: () => {
                props.dispatch(new Msg$2(4, [x_1]));
            },
            onClose: () => {
                props.dispatch(new Msg$2(4, [undefined]));
            },
        }))))), delay(() => {
            let elems_8, children_2, children, children_4;
            return singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_8 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
                width: 30 + "%",
                children: "Job",
            }), createElement("th", {
                width: 20 + "%",
                children: "User",
            }), createElement("th", {
                width: 10 + "%",
                children: "Created",
            }), createElement("th", {
                width: 10 + "%",
                children: "Shift Started",
            }), createElement("th", {
                width: 10 + "%",
                children: "Shift Finished",
            }), createElement("th", {
                width: 10 + "%",
                children: "Status",
            }), createElement("th", {
                width: 10 + "%",
                children: "",
            })]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: reactApi.Children.toArray(Array.from(children_2)),
            })), (children_4 = toList(delay(() => collect((shift) => {
                let elems_7, elems_2, elems_1, elems, elems_4, elems_3, elems_6, elems_5;
                const td = (text) => createElement("td", createObj(ofArray([alignCellTextTop, ["children", text], ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_2) => {
                    props.dispatch(new Msg$2(4, [shift.Id]));
                }]])));
                return singleton(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_7 = [createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_3) => {
                    props.dispatch(new Msg$2(4, [shift.Id]));
                }], (elems_2 = [createElement("div", createObj(singleton_1((elems_1 = [createElement("span", createObj(ofArray([["style", {
                    display: "flex",
                    alignItems: "center",
                }], (elems = [createElement("i", {
                    className: join(" ", toList(delay(() => append(singleton("fa"), delay(() => (shift.IsFinished ? singleton("fa-check") : singleton("fa-hourglass"))))))),
                    style: createObj(toList(delay(() => append(singleton(["marginRight", "8px"]), delay(() => (shift.IsFinished ? singleton(["color", "green"]) : singleton(["color", "orange"]))))))),
                }), createElement("div", createObj(ofArray([alignCellTextTop, ["children", shift.JobTitle]])))], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), td(shift.Username), td(DateTime_dateTimeToStringWithDayName(shift.Created)), td(defaultArg(map(DateTime_dateTimeToStringWithDayName, shift.ShiftStarted), "")), td(defaultArg(map(DateTime_dateTimeToStringWithDayName, shift.ShiftFinished), "")), td(shift.IsFinished ? "Finished" : "In Progress"), createElement("td", createObj(ofArray([["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_4) => {
                    props.dispatch(new Msg$2(4, [shift.Id]));
                }], (elems_4 = [createElement("div", createObj(ofArray([alignCellTextTop, ["key", shift.Id], (elems_3 = [createElement("div", {
                    className: join(" ", ["font-bold"]),
                    children: "",
                })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("td", createObj(singleton_1((elems_6 = [createElement("div", createObj(ofArray([alignIconCenter, (elems_5 = [iconButton("fas fa-edit", (_arg_5) => {
                    props.dispatch(new Msg$2(4, [shift.Id]));
                })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))]))))], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))));
            }, props.state.Entities))), createElement("tbody", {
                children: reactApi.Children.toArray(Array.from(children_4)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_7)),
    });
}

export function searchPanel(props) {
    let children_2, elems, children_6, elems_3, elems_2;
    const children_8 = ofArray([(children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Search"])))), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "w-full"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["placeholder", "Search"], ["readOnly", false], ["onChange", (ev) => {
        let bind$0040;
        props.dispatch(new Msg$2(0, [(bind$0040 = props.state.SearchDetails, new ShiftSearchDetails(ev.target.value, bind$0040.StatusToSearch, bind$0040.DateRange))]));
    }], ["onKeyUp", (ev_1) => {
        PropHelpers_createOnKey(key_enter, (_arg) => {
            props.dispatch(new Msg$2(1, []));
        }, ev_1);
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Search"], ["onClick", (_arg_1) => {
        props.dispatch(new Msg$2(1, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_6 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Status"])))), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems_3 = [createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Status"], ["value", StatusToSearchModule_toString(props.state.SearchDetails.StatusToSearch)], ["onChange", (ev_2) => {
        let bind$0040_1;
        props.dispatch(new Msg$2(0, [(bind$0040_1 = props.state.SearchDetails, new ShiftSearchDetails(bind$0040_1.SearchText, StatusToSearchModule_ofString(ev_2.target.value), bind$0040_1.DateRange))]));
    }], (elems_2 = toList(delay(() => map_1((case$) => createElement("option", {
        value: name_48(case$),
        children: String_splitWord(name_48(case$)),
    }), statusesToSearch))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    });
}

export function IndexView() {
    return createElement(IndexView_2, {
        entityName: "Shifts",
        entityTable: shiftTable,
        searchDetails: new ShiftSearchDetails("", new StatusToSearch_1(0, []), undefined),
        searchFunc: uncurry2(searchFunc),
        searchPanel: searchPanel,
        xPadding: "",
        yPadding: "",
    });
}

